import React from 'react';
import styled from 'styled-components';
import { position, size, fluidRange } from 'polished';
import Contain from './base/Contain';
import breakpoints from '../styles/settings/breakpoints';
import bottomImage from '../images/photo-bottom-2.jpg';
import mobileBottomImage from '../images/photo-bottom-mobile-2.jpg';
import { useInView } from 'react-intersection-observer';
import { useScrollPercentage } from 'react-scroll-percentage';

const Bottom = () => {
    const [inviewRef, inView] = useInView({
        threshold: 0.1
    });

    const [ref, arrowPercentage] = useScrollPercentage({
        threshold: 0.75
    });

    const getImage = () => {
        if (window.innerWidth > 1023) {
            return <img src={bottomImage} alt="" />;
        } else {
            return <img src={mobileBottomImage} alt="" />;
        }
    };

    return (
        <SBottomWrapper>
            <SBottom>
                <SWeekNumber>
                    <SWeekNumberTitle>Week</SWeekNumberTitle> <SWeekNumberValue>39</SWeekNumberValue>
                </SWeekNumber>

                <SBottomSpecs>
                    <Contain>
                        <SBottomSpecsTitle>Yes, hij is geboren!</SBottomSpecsTitle>
                        <SBottomSpecsText>
                            “Laura, Jasper en Loïs vertellen je graag naar welke naam hij (met een beetje geluk) gaat luisteren!”
                        </SBottomSpecsText>
                    </Contain>
                </SBottomSpecs>

                <SWrapper>
                    <SBottomFirstName className="js-sticky-element">
                        FELIX <strong>LYON</strong>
                    </SBottomFirstName>
                    <SBottomLastName className="js-sticky-element" ref={ref} style={{ opacity: 1 - arrowPercentage }}>
                        VAN <br /> ORDEN
                    </SBottomLastName>

                    <SBottomImage>
                        {getImage()}

                        <SBottomSpecs ref={inviewRef}>
                            <Contain>
                                <SBottomSpecsText style={{ opacity: inView ? 1 : 0 }}>
                                    <strong>Ik ben geboren op 6 januari 2025 in Rotterdam.</strong> 49 cm lang en 3420 gram licht. <br />
                                    Zwembaan 16, 3134 KN Vlaardingen / <a href="tel:0648760960"><strong>06 - 48 760 960</strong></a>
                                </SBottomSpecsText>
                            </Contain>
                        </SBottomSpecs>
                    </SBottomImage>

                    <SBottomBackground />
                </SWrapper>
            </SBottom>
        </SBottomWrapper>
    );
};

const SBottomWrapper = styled.div`
    background-color: ${(props) => props.theme.colorSecondary};
`;

const SBottom = styled.div`
    position: relative;
`;

const SBottomBackground = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    background-image: linear-gradient(to bottom, rgba(208, 207, 210, 0), rgba(208, 207, 210, 1));
`;

const SWeekNumber = styled.div`
    position: relative;
    z-index: 1;
    margin-bottom: 60px;
    text-align: center;
    color: rgb(88, 61, 255);
`;

const SWeekNumberTitle = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '16px',
            toSize: '18px'
        },
        '320px',
        '1440px'
    )}
`;

const SWeekNumberValue = styled.div`
    font-family: ${(props) => props.theme.primaryFontBold};

    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '36px',
            toSize: '38px'
        },
        '320px',
        '1440px'
    )}
`;

const SBottomSpecs = styled.div`
    position: relative;
    padding-bottom: 86px;
    text-align: center;
    color: ${(props) => props.theme.colorPrimary};
    line-height: 1.611;
    z-index: 2;

    &::after {
        ${position('absolute', null, null, 0, '50%')}
        width: 164px;
        height: 1px;
        background-color: ${(props) => props.theme.colorPrimary};
        transform: translateX(-50%);
        content: '';
    }

    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '17px',
            toSize: '19px'
        },
        '320px',
        '1440px'
    )}
`;

const SBottomSpecsTitle = styled.h2`
    font-family: ${(props) => props.theme.primaryFontBold};
`;

const SBottomSpecsText = styled.p`
    transition: opacity 0.24s ease-in;

    a {
        display: block;
        color: ${(props) => props.theme.colorBlack};
        text-decoration: none;

        &:visited {
            color: ${(props) => props.theme.colorBlack};
        }

        ${breakpoints.medium} {
            display: inline;
        }
    }
`;

const SWrapper = styled.div`
    position: relative;
    margin-top: 92px;
    z-index: 100;
`;

const SBottomFirstName = styled.span`
    ${position('sticky', '34px', null, null, null)}
    display: block;
    padding-top: 40px;
    padding-right: 5vw;
    padding-left: 5vw;
    color: ${(props) => props.theme.colorBlack};
    text-align: center;
    font-family: Brexton;
    font-size: 80px;
    line-height: .9;
    z-index: 1;

    strong {
        color: ${(props) => props.theme.colorPrimary};
    }

    ${breakpoints.medium} {
        font-size: 120px;
    }

    ${breakpoints.large} {
        padding-right: 0;
        padding-left: 0;
        font-size: 140px;
    }

    ${breakpoints.wide} {
        font-size: 160px;
    }

    ${breakpoints.huge} {
        font-size: 184px;
    }
`;

const SBottomLastName = styled.span`
    ${position('sticky', null, null, null, null)}
    display: block;
    top: 220px;
    padding-right: 5vw;
    padding-left: 5vw;
    color: ${(props) => props.theme.colorBlack};
    transition: opacity 0.36s ease-in;
    text-align: center;
    display: block;
    font-family: Brexton;
    font-size: 80px;
    line-height: .9;

    ${breakpoints.medium} {
        font-size: 110px;
        top: 300px;
    }

    ${breakpoints.large} {
        padding-right: 0;
        padding-left: 0;
        font-size: 140px;
        top: 200px;
    }

    ${breakpoints.huge} {
        font-size: 184px;
        top: 246px;
    }
`;

const SBottomImage = styled.figure`
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 100px;
    height: 100vh;

    ${SBottomSpecs} {
        width: 100%;
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
        color: ${(props) => props.theme.colorBlack};
        padding-bottom: 30px;

        &::after {
            display: none;
        }

        strong {
            font-family: ${(props) => props.theme.primaryFontBold};
        }
    }

    img {
        ${position('absolute', 0, null, null, 0)}
        ${size('100%', '100%')}
        object-fit: cover;
        font-family: 'object-fit: cover;';
        vertical-align: bottom;
        width: 100%;
    }
`;

export default Bottom;
