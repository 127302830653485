import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import Stickyfill from 'stickyfilljs';
import Layout from './components/Layout';
import Top from './components/Top';
import Dot from './components/Dot';
import Week from './components/Week';
import Bottom from './components/Bottom';
import 'intersection-observer';
import { useScrollPercentage } from 'react-scroll-percentage';
import { useInView } from 'react-intersection-observer';
import image2 from './images/photo-2-2.jpg';
import image3 from './images/photo-2-3.jpg';
import image4 from './images/photo-2-4.jpg';
import image5 from './images/photo-2-5.jpg';
import image6 from './images/photo-2-6.jpg';

function App() {
    const [ref, percentage] = useScrollPercentage({
        threshold: 0.1
    });

    const [inviewRef, inView] = useInView({
        threshold: 0.25,
        rootMargin: '1000px 0px 0px 0px'
    });

    const [topInviewRef, topInView] = useInView({});

    useEffect(() => {
        var elements = document.querySelectorAll('.js-sticky-element');
        Stickyfill.add(elements);
    }, []);

    return (
        <div className="App">
            <Layout>
                <ParallaxProvider>
                    <SWrapper ref={ref}>
                        <div ref={topInviewRef}>
                            <Top />
                        </div>
                        <Dot percentage={percentage} inView={inView} showDots={topInView} />

                        <Week
                            content={{
                                weekValue: '9',
                                title: 'Wurmpie',
                                description: {
                                    title: 'Echo',
                                    text:
                                        '“Het bewijs en het mooie nieuws dat we samen een tweede kind mogen verwachten.”'
                                },
                                image: {
                                    src: image2,
                                    alignment: 'left'
                                }
                            }}
                            textColor="rgb(208, 207, 210)"
                        />
                        <Week
                            content={{
                                weekValue: '13',
                                title: 'Olaf',
                                description: {
                                    title: 'Een jongen!',
                                    text:
                                        '“Voor Loïs was het meteen duidelijk. Haar broertje moest de naam van haar Disney-held gaan dragen.”'
                                },
                                image: {
                                    src: image3,
                                    alignment: 'center'
                                }
                            }}
                            textColor="rgb(208, 207, 210)"
                        />
                        <div ref={inviewRef}>
                            <Week
                                content={{
                                    weekValue: '17',
                                    title: 'Roffa',
                                    description: {
                                        title: 'Rotterdam - Vlaardingen',
                                        text:
                                            '“Niemand had dit verwacht. We gaan Rotterdam verlaten. Een nieuwe fase breekt aan in Vlaardingen.”'
                                    },
                                    image: {
                                        src: image4,
                                        alignment: 'left'
                                    }
                                }}
                                textColor="rgb(88, 61, 255)"
                            />
                        </div>

                        <Week
                            content={{
                                weekValue: '26',
                                title: 'LYON',
                                description: {
                                    title: 'Hardlopen',
                                    text:
                                        'Met een flinke buik werd de (halve) marathon van Lyon gelopen. Lyon betekent ‘dappere krijger’ en ‘leeuw’ in het Oudgrieks”'
                                },
                                image: {
                                    src: image5,
                                    alignment: 'left'
                                }
                            }}
                            backgroundColor="rgb(208, 207, 210)"
                            textColor="rgb(88, 61, 255)"
                        />
                    </SWrapper>
                    <Week
                        content={{
                            weekValue: '32',
                            title: ':)',
                            description: {
                                title: 'Wat het moet zijn',
                                text:
                                    '“De naam zong al even rond. We vinden hem krachtig en prettig in het gehoor, maar we horen hem niet vaak. Het betekent ‘voorspoed’, dus dit moest het zijn!”'
                            },
                            image: {
                                src: image6,
                                alignment: 'left'
                            }
                        }}
                        backgroundColor="rgb(208, 207, 210)"
                        textColor="rgb(88, 61, 255)"
                    />
                </ParallaxProvider>

                <Bottom />
            </Layout>
        </div>
    );
}

const SWrapper = styled.div`
    position: relative;
`;

export default App;
