import React from 'react';
import styled from 'styled-components';
import { fluidRange, position } from 'polished';
import { Parallax } from 'react-scroll-parallax';
import { useScrollPercentage } from 'react-scroll-percentage';
import breakpoints from '../styles/settings/breakpoints';
import Contain from './base/Contain';
import Arrow from './Arrow';

const Week = ({ content, backgroundColor, textColor }) => {
    const { weekValue, title, description, image, arrow } = content;

    const [ref, percentage] = useScrollPercentage({
        threshold: 0.5
    });

    const [weekRef, weekPercentage] = useScrollPercentage({
        threshold: 0.5
    });

    return (
        <SWeekWrapper backgroundColor={backgroundColor}>
            <Contain>
                <SWeek ref={weekRef}>
                    <Parallax y={['-150', '300']} tagOuter="div">
                        <SWeekNumber textColor={textColor}>
                            <SWeekNumberTitle>Week</SWeekNumberTitle> <SWeekNumberValue>{weekValue}</SWeekNumberValue>
                        </SWeekNumber>
                    </Parallax>

                    {image && (
                        <SWeekImageWrapper
                            alignment={image.alignment}
                            style={{ opacity: weekPercentage * 1.7 <= 0.75 ? weekPercentage * 1.7 : 0.75 }}
                        >
                            <Parallax y={['500px', '-200px']} tagOuter="div">
                                <SWeekImage src={image.src} alt="" alignment={image.alignment} />
                            </Parallax>
                        </SWeekImageWrapper>
                    )}

                    {arrow && (
                        <SArrowWrapper ref={ref}>
                            <Arrow height={`${520 * percentage}px`} type="primary" />
                        </SArrowWrapper>
                    )}

                    <Parallax y={['50', '-150']} tagOuter="div">
                        <SWeekTitle>{title}</SWeekTitle>
                    </Parallax>

                    <Parallax y={['300', '-800']} tagOuter="div">
                        <SWeekContent textColor={textColor}>
                            <SWeekContentTitle>{description.title}</SWeekContentTitle>
                            <SweekContentText>{description.text}</SweekContentText>
                        </SWeekContent>
                    </Parallax>
                </SWeek>
            </Contain>
        </SWeekWrapper>
    );
};

const SWeekWrapper = styled.div`
    background-color: ${(props) => props.backgroundColor};
`;

const SWeek = styled.div`
    position: relative;
    padding-top: ${window.innerHeight * 0.4}px;
    padding-bottom: ${window.innerHeight * 0.4}px;
    z-index: 100;
`;

const SWeekNumber = styled.div`
    color: ${(props) => props.textColor};
`;

const SWeekNumberTitle = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '16px',
            toSize: '18px'
        },
        '320px',
        '1440px'
    )}
`;

const SWeekNumberValue = styled.div`
    font-family: ${(props) => props.theme.primaryFontBold};

    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '36px',
            toSize: '38px'
        },
        '320px',
        '1440px'
    )}
`;

const SWeekTitle = styled.h2`
    color: ${(props) => props.theme.colorPrimary};
    height: 1em;
    font-family: Brexton;
    line-height: 0.887;
    font-weight: normal;
    text-transform: uppercase;

    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '60px',
            toSize: '200px'
        },
        '320px',
        '1440px'
    )}
`;

const SWeekContent = styled.div`
    margin-left: auto;
    max-width: 290px;
    color: ${(props) => props.textColor};
    text-align: right;
    line-height: 1.579;

    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '17px',
            toSize: '19px'
        },
        '320px',
        '1440px'
    )}

    ${breakpoints.medium} {
        max-width: 310px;
    }
`;

const SWeekContentTitle = styled.h3`
    font-family: ${(props) => props.theme.primaryFontBold};
`;

const SweekContentText = styled.p``;

const SWeekImageWrapper = styled.figure`
    ${position('absolute', '50%', null, null, null)}
    display: flex;
    justify-content: ${(props) => (props.alignment === 'center' ? 'center' : 'flex-start')};
    align-items: flex-end;
    left: ${(props) => (props.alignment === 'center' ? '50%' : '0')};
    width: 100%;
    transform: ${(props) => (props.alignment === 'center' ? 'translate(-50%, -50%)' : 'translateY(-50%)')};
    z-index: -1;

    ${breakpoints.medium} {
        width: auto;
    }
`;

const SWeekImage = styled.img`
    width: 100%;
    margin-left: ${(props) => (props.alignment === 'center' ? 'auto' : '0')};
    margin-right: ${(props) => (props.alignment === 'center' ? 'auto' : 'auto')};
    display: block;

    ${breakpoints.medium} {
        width: 66.66667%;
    }
`;

const SArrowWrapper = styled.div`
    height: 100px;

    > div {
        &::before,
        &::after {
            left: 15%;
        }
    }

    ${breakpoints.medium} {
        > div {
            &::before,
            &::after {
                left: 50%;
            }
        }
    }
`;

export default Week;
